$mainAccent: #003E78; //#023843; // //#003E78;
$secondaryAccent: #fff; //#3FBCAE; // #22BFDF; //#00ffab;
$secondaryAccentText: #ed3e1e; //#FFF578;
$thirdAccent: #fff; //#FE2359; // #FF593F; //#ffba48; //#00ffab;
$thirdAccentText: #ed3e1e; //#990013;

$brightColor: #ed3e1e;

$lightblue: #8ae9ef;


$firstAlternative: #3894D7;
$secondAlternative: #118C75;


html, body {
    margin: 0;
    height: 100%;
    background-color: $secondaryAccent;
}

@font-face {
    font-family: "Klima";
    src: url("resources/fonts/klima-medium-web.woff") format('woff');
}

@font-face {
    font-family: "Klima";
    src: url("resources/fonts/klima-heavy-web.woff") format('woff');
    font-weight: 900;
}

h1 {
    font-family: "Klima", "Montserrat", sans-serif;
    font-size: 32pt;
    text-align: center;
    font-weight: 900;
    text-transform: uppercase;
    color: $secondaryAccentText;
    margin-top: 20px !important;
}

.metric {
    p.number {
        font-size: 40pt !important;
        font-family: "Klima", "Montserrat", sans-serif;
        text-align: center;
        font-weight: 900;
        color: #ccc;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .description {
        color: #555;
    }
}

h2, h3 {
    color: $mainAccent;
    font-family: "Klima", "Montserrat", sans-serif;
    font-weight: 900;
    text-transform: uppercase;
}

.bright {
    color: $brightColor;
}

p.small {
    font-size: 10pt !important;
}

small.source {
    color: #bbb;

    a {
        color: #555;

        &:hover {
            color: #000;
        }
    }
}

#content-container {
    margin-top: 90px;
    min-height: 60vh;
}

.page-container {
    margin-top: 130px;
}

.SocialMediaShareButton {
    float: left;
}

.ui.button.primary {
    background-color: $brightColor;
    font-family: "Klima", "Montserrat", sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    border-radius: 20px;
    color: rgba(255, 255, 255, 0.6);

    &.non-capitalized {
        text-transform: none;
    }

    &.basic {
        border-color: $brightColor !important;
        color: $brightColor !important;
        box-shadow: 0 0 0 1px $brightColor !important;

        &:hover {
            background-color: $brightColor !important;
            color: #fff !important;
            box-shadow: 0 0 0 1px $brightColor !important;
        }
    }

    &:hover {
        background-color: $brightColor;
        color: #fff;
    }

}


a {
    color: $brightColor;

    &:hover {
        color: #555;
    }
}

.menu-container {

    margin: 0 !important;
    width: 100%;
    //min-height: 80px;
    background-color: #fff;
    z-index: 99999;
    padding: 5px 0;

    .row {
        padding: 0 !important;
    }

    img {
        //position: absolute;
        float: left;
        margin-left: 0;
        margin-top: -5px;
        width: 110px;
        padding: 12px;
        padding-top: 20px;
        background: $mainAccent; // #03114c;
    }

    @media only screen and (max-width: 767px) {
        ul.menu.topics {
            clear: both;
        }
    }

    .crowdfunding-button {
        float: right;
        -webkit-transition: all 1s; /* Safari */
        transition: all 1s;
        padding: 10px;

        .button {
        }

        .invisible {
            //display: none;
            opacity: 0;
            -webkit-transition: all 1s; /* Safari */
            transition: all 1s;

        }

    }

    ul.menu {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            margin: 0 10px;
        }

        a {
            display: inline-block;
            margin: 0 5px;
            font-size: 10pt;
            //font-weight: 800;
            color: $mainAccent;
            //opacity: 0.8;
            font-family: "Klima", "Montserrat", sans-serif;
            font-weight: 900;

            &:not(.button) {
                padding-top: 10px;

                &:hover, &.active {
                    color: $brightColor;
                    //opacity: 0.8;
                }

            }

            &.button {
            }

        }

        &.actions a {
            //color: $brightColor !important;
            font-size: 12pt;
            text-transform: uppercase;
        }

        &.topics {
            margin-top: 0;

            a {
                font-weight: 100;
                color: #555;
                text-transform: none !important;
                font-size: 11pt;
            }
        }

    }

}


@media only screen and (min-width: 768px) { /* > tablet */
    .menu-container {
        position: fixed;
        top: 0;
        left: 0;
        padding: 10px;

        img {
            margin-left: 40px;
            margin-top: -10px;
        }
    }
    ul.menu.actions a {
        font-size: 15pt !important;
        margin-top: 5px;
    }
    ul.menu.topics a {
        font-size: 12pt !important;
        margin-top: 5px;
    }


}

.masthead {
    //padding: 20px;
    //margin-top: 80px !important;

    .crowdfunding {
        margin: 50px;
        margin-top: 180px;

        position: relative;
        z-index: 100;

        .ui.card .header {
            text-align: center;
        }


    }

    //h2 {
    //  margin-left: 38px;
    //  margin-top: -10px;
    //  width: 500px;
    //  color: $lightblue;
    //  //opacity: 0.9;
    //  font-size: 16pt;
    //}

    .slogan {
        position: absolute;
        top: 40px;
        left: 5%;
        //color: $brightColor;
        max-width: 800px;

        h2 {
            color: #fff;
            font-size: 26pt;
        }
    }

    .homeGridNew {
        padding-top: 40px;

        .padding {
            padding: 20px;
        }

        h1 {
            text-align: left;
            font-size: 40pt;
        }

        .line {
            width: 100%;
            height: 30px;
            background: no-repeat;
            background-image: url('resources/images/brown-vertical-line.jpg');
        }

        h2 {
            //color: #fff;
            font-size: 22pt;
            min-height: 100px;
        }

        h3 {
            //color: #fff;
        }

        .cancellation {
            font-size: 12pt;
            color: #fff;
            font-weight: bold;
            opacity: 0.9;
            margin: 20px 0;
            border-left: 10px solid #d98e90;
            padding: 20px;
            line-height: 15pt;
            background-color: rgba(0, 0, 0, 0.1);

            a {
                color: #eee;
            }

        }

        .railtothecop {
            font-size: 12pt;
            //color: #fff;
            font-weight: bold;
            opacity: 0.9;
            margin: 20px 0;
            border-left: 10px solid #e8eae6;
            padding: 20px;
            line-height: 15pt;
            background-color: rgba(0, 0, 0, 0.1);

            a {
                color: #eee;
            }

        }

    }

    .events {
        position: absolute;
        bottom: 80px;
        margin-left: 4%;

        a {
            //background-color: #fff;
            color: #fff;
            padding: 10px;
            border-radius: 15px;
            margin-right: 10px;
            display: flex;
            margin-bottom: 10px;
            background-color: rgba(0, 0, 0, 0.7);

            .number {
                text-align: center;
                float: left;
                display: block;
                font-size: 30pt;
                font-weight: 900;
                font-family: 'Klima', sans-serif;
                width: 100px;
                padding-top: 6px;

                span {
                    display: block;
                    font-size: 10pt;
                    margin-top: 8px;
                }
            }

            strong {
                font-family: 'Klima', sans-serif;
                font-weight: 900;
            }

            &:hover {
                //background-color: #eee;
                opacity: 0.8;
            }
        }

    }


    .button.sail-with-us {
        position: absolute;
        bottom: 8%;
        left: 5%;
    }

    .background-image {
        position: relative;
        min-height: 94vh; //80vh;
        width: 100%;
        //height: 100%;
        //background: #fff url('/resources/images/1477319018.jpg') 100% 100%;
        //background-size: cover;
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        //background: linear-gradient(
        //                to bottom,
        //                transparent, /* or is it color-mod()? */
        //                rgba(0, 0, 0, 0.5)
        //);
    }

    //.map-image {
    //  position: absolute;
    //  bottom: 30%;
    //  left: 5%;
    //  max-width: 300px;
    //  background-color: rgba(0, 0, 0, 0.4);
    //}

}

//.masthead {
//  height: 80vh;
//  width: 100%;
//  //height: 100%;
//  background: #fff 100% 100%;
//  background-size: cover;
//  background-image: url('images/55447004_590012131477236_2063544336175333376_o.jpg');
//
//  .background {
//
//  }
//
//  .content {
//    position: relative;
//    color: #fff;
//    height: 100%;
//
//    background: linear-gradient(
//                    to bottom,
//                    transparent, /* or is it color-mod()? */
//                    rgba(0, 0, 0, 0.5)
//    );
//
//
//    .logo {
//      //background: #013D78;
//      //padding: 5px;
//      position: absolute;
//      bottom: 20%;
//      left: 7%;
//
//      img {
//        width: 550px;
//      }
//
//    }
//
//    //h1 {
//    //  position: absolute;
//    //  bottom: 30%;
//    //  left: 10%;
//    //  font-size: 100pt;
//    //
//    //  span {
//    //    //font-family: serif;
//    //    font-weight: 900;
//    //  }
//    //}
//
//    h2 {
//
//      position: absolute;
//      bottom: 13%;
//      left: 10%;
//      font-size: 18pt;
//      font-family: "Montserrat", sans-serif;
//      width: 600px;
//    }
//
//  }
//
//}

.footer {

    a {
        color: #cecece;

        &:hover {
            color: #fff;
        }
    }

    &.segment {
        padding: 5em 0em;
    }

    ul.menu {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li.part a {
            font-family: "Klima", "Montserrat", sans-serif;
            font-weight: 900;
            text-transform: uppercase;
        }

    }

    h2 {
        color: #ccc;
        font-size: 14pt;
    }

}

.secondary.pointing.menu .toc.item {
    display: none;
}

@media only screen and (max-width: 700px) {
    .ui.fixed.menu {
        display: none !important;
    }
    .secondary.pointing.menu .item,
    .secondary.pointing.menu .menu {
        display: none;
    }
    .secondary.pointing.menu .toc.item {
        display: block;
    }
    .masthead.segment {
        min-height: 350px;
    }
    .masthead h1.ui.header {
        font-size: 2em;
        margin-top: 1.2em;
    }
    .masthead h2 {
        margin-top: 0.5em;
        font-size: 1.2em;
    }
}


/** Custom **/

.header-logo-container {

    margin-top: 50px;

    h2 {
        font-size: 24pt;
        font-weight: 600;
        //opacity: 0.8;
        //color: #000;

        span {
            //background-color: rgba(251, 255, 26, 0.3);
        }
    }

    .logo {
        width: 140px;
        margin-top: 100px;
    }

    h4 {
        font-size: 18pt;
        //color: #000;
        opacity: 0.8;
        //text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
    }

    .bullets {

        //color: #000;
        opacity: 0.8;
        font-size: 12pt;
        //text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);

        .line {
            font-weight: 100;
            font-size: 30pt;
            color: #000;
            line-height: 24px;
            opacity: 0.4;
        }

    }

}

img.wave {
    margin: 0;
    max-width: 80px !important;
    max-height: 80px !important;
    margin-top: -30px;
    margin-bottom: -10px;

}

video#bgvid {
    position: fixed;
    //top: 50%;
    //left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    //z-index: -100;
    //-ms-transform: translateX(-50%) translateY(-50%);
    //-moz-transform: translateX(-50%) translateY(-50%);
    //-webkit-transform: translateX(-50%) translateY(-50%);
    //transform: translateX(-50%) translateY(-50%);
    //background: url(polina.jpg) no-repeat;
    //background-size: cover;
}


.ui.segment.image-header {
    background-image: url("resources/images/emily-campbell-327238-unsplash.jpg");
    background-size: cover;
}

//.ui.segment.image-header {
//
//  height: 100vh;
//  overflow: hidden;
//  position: relative;
//
//  .background, .parallax-content{
//    position: absolute;
//    width: 100%;
//    top: 0;
//  }
//
//  .background {
//    height: 200%;
//    top: -100%;
//    background: url('images/emily-campbell-327238-unsplash.jpg') fixed;
//    background-size: cover;
//  }
//}
.ui.segment.parallax {

    height: 500px;
    overflow: hidden;
    position: relative;

    .background, .parallax-content {
        position: absolute;
        width: 100%;
        top: 0;
    }

    .background {
        height: 200%;
        top: -100%;
        background-size: cover;
        background-position: bottom;
    }

    &.parallax1 .background {
        background-image: url('resources/images/DSC02551.jpg');
    }

    &.parallax2 .background {
        background-image: url('resources/images/airplane.jpg');
    }

    &.parallax3 .background {
        background-image: url('resources/images/DSC05742.jpg');
    }

    &.parallax4 .background {
        background-image: url('resources/images/errol-337193 copy.jpg');
    }

    &.parallax5 .background {
        background-image: url('resources/images/DSC05819.jpg');
    }

    &.parallax6 .background {
        background-image: url('resources/images/DSC05763.jpg');
    }

    &.parallax7 .background {
        background-image: url('resources/images/DSC03992.jpg');
    }

}


.ui.segment.padded, .ui.stripe.padded {
    padding: 80px 0;

    &.first {
        padding-top: 40px;
    }

    &.team {
        padding-bottom: 30px;
    }

    &.no-bottom {
        padding-bottom: 0;
    }

}


.ui.large.segment {

    min-height: 400px;
    //background-color: $mainAccent;

    h1 {
        margin: 200px 0;
        font-size: 45pt;
        color: #fff;
    }
}

.ui.large.segment.padded {

    h1, h2 {
        margin: 0;
        padding: 0;
        color: $secondaryAccentText;
    }


    .column.large {
        font-size: 16pt;
        line-height: 24pt;
    }
}


blockquote, p {
    font-size: 12pt;
    line-height: 1.4285em;
}

.ui.segment {

    p {
        font-size: 12pt;
    }

    img.image {
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
    }

    img.icon {
        display: block;
        max-width: 120px !important;
        max-height: 100px !important;
        margin: 0 auto;
    }

    ul.list {
        //text-align: left;
        //list-style-type: circle;
        list-style-type: none;
        margin-top: 30px;

        li {
            font-size: 14pt;
            line-height: 28pt;
            margin-bottom: 20px;
        }

        li.wave {
            margin: 0;
        }
    }

}

.demands h2 {
    margin-top: 0;
}

.header-logo-container {
    padding-top: 50px;
}

.ui.card.subscribe {
    color: #000;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 500px;
}

.ui.card.subscribe .form {
    text-align: left;
    margin-top: 10px;
}

.ui.stripe.white-background {
    //background-color: #fff;
}

.ui.stripe.colored-background {
    //background-color: $secondaryAccent;

    h1 {
        color: $secondaryAccentText;
    }
}

.ui.stripe.colored-background-alternative {
    background-color: $thirdAccent;

    h1 {
        color: $thirdAccentText;
    }
}

h5 {
    margin-bottom: 0;
}

.ui.card.small-text p {
    font-size: 10pt;

}

.ui.card.main-card {
    float: right;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    min-width: 350px;
}


#map-atlantic {
    height: 810px;
    background: $secondaryAccent url('resources/images/map-atlantic2.png') no-repeat;
    background-size: auto 100%;
    background-position-x: center;
    text-align: center;

    #map {
        position: relative;
        height: 810px;
        //width: 100%;
        //width: 1531px;
        width: 500px; //941px;
        //background-color: rgba(255, 255, 255, 0.1);
        margin: 0 auto;
        border: none;
    }

    .logo-container {
        position: absolute;
        bottom: 20px;
        width: 100%;
        text-align: center;

        .logo {
            width: 110px;
        }

    }

    .map-box {
        background-color: #fff;
        position: absolute;
        width: 240px;
        //border: 3px solid $mainAccent;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        -webkit-transition: all 1s; /* Safari */
        transition: all 1s;

        &.cop {
            bottom: 0;
            left: 60px;
            opacity: 0;
        }

        &.trip {
            top: 30%;
            right: 60px;
            opacity: 0;
        }

        &.start {
            top: 15%;
            left: 60px;
        }

        .padding {
            padding: 10px;
        }

        h2 {
            margin-bottom: 0;
        }
    }

    .map-point {
        $size: 10pt;
        position: absolute;
        background-color: $brightColor;
        width: $size;
        height: $size;
        border-radius: $size/2;

        &.casablanca {
            top: 300px;
            left: 240px;
            opacity: 0.1;
        }

        &.tenerife {
            top: 320px;
            left: 197px;
            opacity: 0.1;
        }

        &.caboVerde {
            top: 380px;
            left: 165px;
            opacity: 0.1;
        }

        &.recife {
            top: 490px;
            left: 110px;
            opacity: 0.1;
        }

        &.rio {
            top: 560px;
            left: 80px;
            opacity: 0.1;
        }
    }

    .map-label {
        position: absolute;

        &.departure {
            top: 160px;
            left: 50px;
        }

        &.arrival {
            top: 605px;
            left: -20px;
        }
    }

    .top-arrow-down-container {
        position: absolute;
        top: 30px;
        width: 100%;
        text-align: center;

        img {
            height: 40px;
        }

    }

    .arrow-down-container {
        position: absolute;
        bottom: -50px;
        width: 100%;
        text-align: center;

        img {
            height: 40px;
        }

    }

}

#moving-unit {
    position: absolute;
    left: 290px;
    //left: 580px;
    //left: 620px;
    top: 175px;
    //top: 125px;
    width: 80px;
    margin-top: -40px;
    margin-left: -40px;
}

h2.label {
    display: inline;
    padding: 4px 8px;
    border: 1px solid #000;
    color: #000;
    border-radius: 5px;
    font-family: inherit;
    font-weight: 400;
    font-size: 15pt;
    opacity: 0.7;
}


.timeline {
    width: 90%;
    max-width: 700px;
    margin: 50px auto;
    margin-top: 80px;
    position: relative;

    &.mobile.only {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .line {
        border-top: 4px dashed $brightColor;
    }

    $size: 14px;


    .title {
        //background-color: rgba(255, 255, 255, 0.1);
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
        font-size: 10pt;
        line-height: 10pt;
    }

    .description {
        color: rgba(0, 0, 0, 0.7);
        font-size: 9pt;
        line-height: 8pt;
    }

    .point-container {
        position: absolute;
        top: -5px;
        width: $size;

        -webkit-transition: left 1s; /* Safari */
        transition: left 1s;

        .point {
            width: $size;
            height: $size;
            border-radius: $size / 2;
            border: 3px solid $brightColor;
            background-color: $secondaryAccent;
            margin: 0 auto;
        }

        .title {
            //background-color: rgba(255, 255, 255, 0.1);
            position: absolute;
            top: -50px;
            text-align: center;
            width: 120px;
            margin-left: -60px + $size / 2;
        }

        .description {
            padding-top: 5px;
            width: 80px;
            margin-left: -40px + $size / 2;
            text-align: center;
        }

        &.point-2 {
            left: 15%;
        }

        &.point-3 {
            right: 20%;
        }

        &.point-4, &.end {
            right: 0;
        }

        &.threshold {
            left: 33%;
        }


    }


    .icon {
        position: absolute;
        $size: 50px;
        width: $size;
        height: $size;
        top: -46px;
        opacity: 0.7;

        &.bus {
            right: 8%;
            top: -36px;
            background: url('resources/images/np_bus_1157326_000000.png');
            background-size: 100% 100%;
        }

        &.ship {
            left: 40%;
            background: url('resources/images/np_sail-boat_6475_000000.png');
            background-size: 100% 100%;
        }

    }

    &.mobile.only .icon {
        position: inherit;
        top: auto;
        left: auto;
        right: auto;
        display: block;
        margin: 0 auto;
        margin-top: 20px;
    }

    &.crowdfunding {
        .description {
            top: -26px;
        }

        .line {
            border-color: $mainAccent;
        }

        .gradient {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 30%);
        }

        .point-container {
            .point {
                border-color: $mainAccent;
            }

            &.current {
                .point {
                    border-color: $brightColor;
                }

                .description {
                    color: #000;
                    font-weight: 700;
                    background-color: #fff;
                    border-radius: 5px;
                    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 5%, rgba(255, 255, 255, 1) 95%, rgba(255, 255, 255, 0) 100%);
                }

                .icon {
                    left: -15px;
                    top: -50px;
                }
            }
        }
    }


}


.column.no-padding { //.ui.grid > .row >
    padding: 0;

    &.list .item .content {
        display: block;
        padding: 10px;
        color: #555;

        &.flex {
            display: flex;
        }

        .text {
            width: 100%;
        }

        .checkbox-container {
            height: 100%;
            padding: 20px 8px;
            padding-left: 0;
            padding-right: 12px;
        }

        &:hover {
            color: #000;
        }

        &.selected {
            background-color: #f9f9f9;
            color: $brightColor;
        }

        h4 {
            margin-bottom: 0;
            padding-bottom: 0;
        }

        p {
            font-size: 10pt;
            margin-top: 0;
        }

    }

}

.persons {
    h3 {
        margin-bottom: 0;
    }

    h5 {
        margin: 0;
    }

    a.mail-link {
        display: block;
        margin-bottom: 10px;
    }
}

.persons, .team {


    img.person {
        $size: 120pt;
        width: $size;
        //height: $size;
        border-radius: $size / 2;
        border: 4px solid #fff;
        background-color: #eee;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }

    &.mobile {

        h3 {
            font-size: 10pt;
            word-wrap: break-word;
            margin: 0;
            margin-top: 4px;
        }

    }

    .column.person {
        text-align: center !important;

        &.active {
            //background-color: #fafafa;
            h3 {
                color: $brightColor;
            }

            img.person {
                box-shadow: 0 0 14px rgba(0, 0, 0, 0.4);
            }
        }

    }

    &.persons p {
        color: #555;
    }

    &.padded {
        padding-bottom: 0;
    }

    blockquote {
        padding: 0;
        margin: 0;
        color: #555;
        font-size: 11pt;

        &.large {
            padding-left: 14px;
            border-left: 5px solid #ccc;
            margin-bottom: 8px;
            color: #000;
        }

    }

    .participantDetails {
        //background-color: #fafafa;

        p {
            font-size: 11pt;
        }

        blockquote {
            font-size: 11pt;
        }
    }

    //&.sponsors {
    blockquote {
        font-size: 10pt;
        line-height: 12pt;
        color: #444;
    }

    //}

}

.team {

    background-color: #eee !important;

    h3 {
        margin-bottom: 0;
    }

    h5 {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    img.person {
        $size: 100pt;
        width: $size;
        height: $size;
        border-radius: $size / 2;
    }

}

img.ui.transparent {
    background-color: #eaeaea;
}


.countdown {
    //text-align: center;
    position: absolute;
    top: 20px;
    right: 0;
    opacity: 0.9;

    margin-right: 20px;
    color: #333;
    z-index: 10;

    .numbers {

        .number {
            display: inline-block;
            padding: 10px 20px;
            text-align: center;
            width: 120px;

            .digits {
                font-family: 'Klima', serif;
                font-weight: 900;
                font-size: 50pt;
                //text-shadow: 0 0 20px $mainAccent;
            }

            span {
                display: block;
                font-size: 12pt;
                margin-top: 22px;
                font-family: 'Klima', serif;
                font-weight: 900;
                text-transform: uppercase;
            }
        }

    }

    .description {
        font-size: 13pt;
        font-weight: bold;
        text-align: right;
        padding-right: 10px;

        a {
            color: #111;
        }
    }

}

@media only screen and (max-width: 767px) {

    #content-container {
        margin-top: 10px;
    }

    h1 {
        font-size: 24pt;
    }

    .ui.segment.padded {
        padding: 40px 0;

        &.first {
            padding-top: 0;
        }

    }

    .ui.card.no-border-mobile {
        border: none;
        box-shadow: none;

    }

    .countdown {
        margin-left: 20px;
        margin-top: 90px;

        .numbers .number {
            width: 70px;
            padding: 0px;

            .digits {
                font-size: 20pt;
            }

            span {
                font-size: 10pt;
                margin-top: 4px;
            }
        }

        .description {
            padding-left: 12px;
            font-size: 11pt;
            font-weight: bold;

        }
    }

}

.left-right-padded {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.publicity {
    .ui.header {
        margin: 0;
    }

    .ui.image {
        max-height: 60px;
    }
}

.crowdfunding-page {
    .small-persons {
        img.ui.image {
            display: inline-block;
            margin: 5px;
        }

        .read-more {
            display: inline-block;

        }
    }


    .large-number {
        display: inline-block;
        margin-right: 40px;

        .number {
            display: block;
            font-size: 20pt;
            font-family: 'Klima', sans-serif;
            font-weight: 900;
            color: $mainAccent;
            margin-bottom: 4px;
        }
    }

}

.crowdfunding-numbers {

    .large-number {
        display: inline-block;
        width: 32%;
        float: left;
        padding: 5px 10px;
        font-size: 8pt;
        line-height: 9pt;
        text-align: center;
        //margin-right: 40px;

        .number {
            display: block;
            font-size: 20pt;
            font-family: 'Klima', sans-serif;
            font-weight: 900;
            color: $mainAccent;
            margin-bottom: 4px;
        }
    }
}

.blogs {

    .column {
        //padding: 0 !important;
    }

    .imageContainer {
        height: 200px;
        overflow: hidden;

        img {
            margin: 0;
            padding: 0;
            max-width: 100%;
        }
    }

    .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 10px;

        h1 {
            color: #fff !important;
            font-size: 18pt;
        }

    }

    h2 {
        margin-bottom: 30px;
    }

    .date {
        position: absolute;
        top: 10px;
        right: 20px;
    }

    .author {
        position: absolute;
        top: 40px;
        right: 20px;
    }

    .content {
        font-size: 12pt;
        line-height: 17pt;
    }
}


.markdown-container {

    img {
        display: block !important;
        margin: 0 auto;
        max-width: 100%;
        max-height: 400px;
    }

}

.home {
    h1 {
        //font-size: 24pt;
        //text-align: left;
    }

    .ui.grid .row {
        //padding: 0;
    }
}

.page-buttons {

    .column {
        height: 200px;
        text-align: center;

        h2 {
            color: #fff;
            padding: 4px 0;
        }

        &.journey {
            background-color: $firstAlternative;
        }

        &.travel {
            background-color: $secondAlternative;
        }

        &.sail {
            background-color: $mainAccent;
        }

        span {
            color: #fff;
            opacity: 0.8;
            font-size: 12pt;
        }

        &:hover {
            h2 {
                font-size: 20pt;
                padding: 2px 0;
            }

            span {
                opacity: 1;
            }
        }

    }

    .padding {
        padding: 20px;
        padding-top: 50px;
    }

}

#windy {
    width: 100%;
    height: 600px;
}

.railtothecop {

    h3 span {
        font-size: 26pt;
        padding-right: 12px;
    }

    a.letter img {
        border: 6px solid #ccc;

        &:hover {
            border-color: $brightColor;
        }
    }
}

#home-gya {
    display: none;
    position: absolute;
    bottom: 100px;
    right: 20px;
    max-width: 250px;
}

#home-gya2 {
    display: none;
    text-align: center;
}


@media only screen and (min-width: 768px) { /* > tablet */
    #home-gya {
        display: block;
    }

}

@media only screen and (max-width: 767px) { /* > tablet */
    #home-gya2 {
        display: block;
    }

}

.output {

    padding-top: 0;

    .part {
        padding-top: 50px;
    }

    p, li {
        font-size: 15pt;
        line-height: 20pt;
        text-align: left;
    }

    .fullHeight {
        min-height: 94vh;

        &.vision {
            background: url("/resources/images/output/ship-small.jpg") no-repeat bottom left;

            .right-column {
                display: flex;
                flex-direction: column;
                align-content: stretch;
                text-align: right;

                .space {
                    flex-grow: 2;
                }

                .onepager {
                    min-height: 10px;
                    flex-grow: 1;
                }

            }

            .video {
                //position: relative;
                //width: 100%;
                //height: 100%;

                .ui.embed {
                    position: absolute;
                    bottom: 200px;
                    width: 100%;
                    right: 0;
                }

            }

        }

        &.problem {

            .left-column {
                //background: url("/resources/images/output/background-blue-1.jpg") no-repeat center bottom;


            }

        }

        .sun {
            position: absolute;
            top: 50px;
            right: 0;
            width: 300px;
            //opacity: 0.5;
        }

    }

    .small {
        font-family: "Klima", "Montserrat", sans-serif;
        font-size: 14pt;
        text-align: center;
        font-weight: 900;
        color: #555;
        margin-top: 18px !important;
        text-transform: uppercase;
        background: url("/resources/images/output/line.jpg") no-repeat center center;;
        padding-bottom: 40px;
        //height: 100px;
    }

    .huge {
        font-family: "Klima", "Montserrat", sans-serif;
        font-size: 32pt;
        text-align: left;
        font-weight: 900;
        color: $secondaryAccentText;
        margin-top: 20px !important;
        line-height: 35pt;
        background-color: #fff;
    }

    .quote {
        font-size: 16pt;
        text-align: left;
        font-weight: 200;
        color: #555;
        margin-top: 20px !important;
        margin-bottom: 20px;
        width: 80%;
        line-height: 28pt;
        padding-left: 40px;
        background-color: #fff;
    }

    .bordered-image {
        position: relative;
        background-size: cover;

        img {
            //position: absolute;
            //top: 0;
            width: 100%;
            height: 100%;
        }
    }


    .demands {

        .demand {
            position: relative;
            display: flex;
            margin-bottom: 30px;
            min-height: 130px;
            //background-size: 100%;

            .line {
                position: absolute;
                width: 100%;
                left: -50px;
                height: 120px;
                bottom: 0;
            }

            &.right .line {
                bottom: -60px;
                left: -10px;
            }

            .text-container {
                position: relative;
                padding-bottom: 50px;

                h3 {
                    margin: 0;
                }

                p {
                    font-size: 13pt;
                }
            }

            .image-container {
                min-width: 100px;
                text-align: center;
                padding: 10px;

                img {
                    max-width: 80%;
                }
            }

        }

        .left .demand {
            //background: url('/resources/images/output/demand-line-left.jpg') bottom left no-repeat;
        }

        .right .demand {
            //background: url('/resources/images/output/demand-line-right.jpg') bottom left no-repeat;
        }

    }

    .opportunities {
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                margin-bottom: 4px;
            }
        }
    }

    .solutions-new {

        .column {
            display: flex !important;
            justify-content: center;
            flex-direction: column;
        }

        .solution {
            padding: 20px;
            text-align: center;
            background: url('/resources/images/output/circle-background.jpg') no-repeat center center;
            background-size: 100% auto;
            flex-grow: 0;

            .category {
                color: #838383;
                font-style: italic;
            }

            h2 {
                font-size: 18pt;
                line-height: 22pt;
            }

            h3 {
                margin: 0;
                color: #444;
                font-size: 12pt;
            }

            p {
                font-size: 13pt;
            }

            &.solution:hover {
                h2, h3 {
                    color: #333;
                }
            }

        }

    }

    .solutions {

        .solution {
            position: relative;
            display: flex;
            margin-bottom: 10px;
            min-height: 250px;

            .line {
                position: absolute;
                width: 200px;
                left: -100px;
                //height: 120px;
                top: -40px;
            }

            .image-container {
                min-width: 100px;
                flex-grow: 0;
                padding-right: 20px;
                text-transform: uppercase;
                font-weight: 900;
                color: #555;
            }

            &.right {
                text-align: right;

                .line {
                    //bottom: -60px;
                    left: auto;
                    right: -40px;
                }

                .image-container {
                    text-align: right;
                    padding-right: 0;
                    padding-left: 20px;
                }

                p {
                    text-align: right;
                }
            }

            .text-container {
                position: relative;
                padding-bottom: 50px;
                flex-grow: 1;

                h2 {
                    margin: 0;
                }

                h3 {
                    margin: 0;
                    color: #444;
                }

                p {
                    font-size: 13pt;
                }
            }

        }


    }

}